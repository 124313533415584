.dark {
  body {
    background-color: #161616;
    border-color: #1f1f1f;
    color: white;
  }
  input {
    color: #fff;
    &:hover,
    &:focus {
      outline: none !important;
      background-color: none !important;
      color: white;
      caret-color: white;
    }
  }
  hr {
    border: none;
    background-color: #1f1f1f;
  }
  span:not(.text-primary) {
    color: #fff;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  blockquote,
  div,
  td,
  th,
  a {
    &:not(.text-primary) {
      color: #fff;
    }
  }
  p:not(.text-primary, .dark-values),
  .more-container li,
  .more-container a,
  .more-container h3 {
    color: #fff;
  }
  .dark-values {
    color: #6acc32;
  }
  footer,
  .react-share__ShareButton,
  nav,
  form,
  div:not(.banner, .banner div, td div, .dark-widget div, .dark-widget, .dark-latest, .dark-latest div, .dark-primary, .dark-gif, .dark-gif div, .dark-gif aside, .dark-feedback, .advertising-swiper, .swiper-wrapper, .my-modal,.swiper,.custom-swiper,.swiper-slide) {
    background-color: #0c0c0c;
    border-color: #1f1f1f;
    color: #fff;
  }
  button:not(.active-news, .dark-primary, .dark-latest button, .react-share__ShareButton),
  textarea,
  .rc-menu,
  .rc-menu-item {
    background-color: #161616;
    border-color: #1f1f1f;
    color: #fff;
  }
  input {
    background-color: #161616;
  }
  .dark-widget,
  .dark-latest {
    div:not(.dark-red, .recharts-responsive-container div) {
      background-color: #161616;
      border-color: #1f1f1f;
    }
  }

  // .page-link {
  //   background-color: #0d1a3a !important;
  // }
  .news_pagination li:first-child > .page-link,
  .news_pagination li:last-child > .page-link {
    background-color: #161616;
  }
  .rc-menu-item:hover,
  input:hover,
  textarea:hover {
    background: none;
  }
  input::placeholder {
    color: #fff;
  }
  .active-news {
    background-color: #161616;
    border-color: #1f1f1f;
  }
  .activeLink {
    background-color: #161616;
    border-color: #1f1f1f;
    span {
      color: #7c42f3;
    }
  }
  .dark-feedback {
    background-color: white;
    * {
      background-color: white;
    }
  }
  button {
    svg path {
      fill: white;
    }
  }

  @media (min-width: 1024px) {
    .homeGeneral {
      background-image: url('/background-dark.svg');
      background-repeat: no-repeat;
      background-position: -2px -57px;
    }
  }
  .advertising-swiper .swiper-button-prev,
  .advertising-swiper .swiper-button-next {
    border: 1px solid #1f1f1f;
  }
  .tox .tox-edit-area__iframe {
    background-color: transparent !important;
  }

  .news_article {
    h3,
    h4,
    h5,
    h6,
    p strong {
      font-size: 20px;
      line-height: 29px;
      font-weight: 500;
      color: #7c42f3;
    }
  }
}
